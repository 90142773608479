/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.my-tollbar {
    padding: 0 16px !important;
}

.item-native {
    padding-left: 5px !important;
}

.mt-40 {
    margin-top: 40px;
}
.mt-20 {
    margin-top: 20px;
}
.mb-40 {
    margin-bottom: 40px;
}

ion-content {
    --ion-background-color: #F4FBFF;
}

ion-input {
    ::placeholder {
        font-size: 14px !important;
        letter-spacing: 0.16px;
        color: #222222;
        opacity: 0.2;
    }
}

ion-select {
    max-width: 100%;
    // min-width: 150px;
    width: 100%;
    /* Applies to the value and placeholder color */
    color: #222222;
    /* Set full opacity on the placeholder */
    --placeholder-opacity: 0.5;
    padding: 0px;
    font-size: 14px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

.selecter-acquisition {
     color: #ffffff;
}

.selecter-cco {
    ion-radio {
        --color-checked: red !important;
        color: #222222;
    }
}

ion-datetime {
    color: #222222;
    opacity: 0.5;
    font-size: 14px;
    padding: 0px;
}

.label-stacked {
    letter-spacing: 1.3px;
    color: #646D82;
    font-size: 13px;
}

.error-msg {
    font-size: 12px !important;
    padding-top: 5px;
}

form,
.form {
    ion-item {
        margin-top: 10px;
        --background: #f1f1f1 !important;
        color: #222 !important;
    }
}

.width-100 {
    width: 100px !important;
}

.custome-color {
    background-color: #f1f1f1 !important;
}

.empty-bg {
    border-color: #14A83C !important;
    color: #14A83C !important;
    float: right;
    border-radius: 5px;
}

.close-icon {
    font-size: 30px;
    color: #000;
    cursor: pointer;
}

.remove-data-icon {
    position: absolute;
    right: 16px;
    top: 10px;
}

// .button {
//   min-width: 150px !important;
// }
.edit-cancel {
    --background: transparent;
    --box-shadow: 0;
    // border: 1px solid #999;
    border-radius: 4px;
    --padding-start: 5px;
    --padding-end: 5px;
}

.input-wrapper {
    background-color: #f1f1f1;
}

p {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}

ion-card {
    --background: #fff;
}

.title-header {
    margin-top: 0px;
    --background: transparent;
    color: #222 !important;
    border-bottom: 1px dotted;

    h5 {
        margin-top: 15px;
    }

    // --background: #e5ffec;
}

.ion-3x {
    position: relative;
    font-size: 2em;
    vertical-align: middle;
    color: #000;
}

.pt-8 {
    padding-top: 8px;
}

.pl-5 {
    padding-left: 5px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-60 {
    margin-bottom: 60px;
}

.margin-lr-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.ml-30 {
    margin-left: 30px;
}

.ionic-align-middle {
    vertical-align: middle;
}

hr {
    height: 1px;
    border-width: 0;
    box-sizing: content-box;
    background-color: #949494 !important;
    margin: 16px 0 !important;
}

.alert-icon {
    background-color: #14a83c;
    color: #fff;
    border-radius: 50%;
}

.custum-menu {
    .popover-content {
        right: 13% !important;
        top: 65px !important;
        left: auto !important;
        // width: 180px;
    }
}

// @media only screen and (min-width: 1000px) {
//     .custum-menu {
//         .popover-content {
//             right: 25% !important;
//             top: 65px !important;
//             left: auto !important;
//             // width: 180px;
//         }
//     }
//   }
//   @media only screen and (min-width: 1500px) {
//     .custum-menu {
//         .popover-content {
//             right: 13% !important;
//             top: 65px !important;
//             left: auto !important;
//             // width: 180px;
//         }
//     }
//   }
.no-box-shadow {
    box-shadow: none !important;
    border: 1px solid;
}

.custome-segment {
    width: 260px;
    border: 1px solid #14A83C;
    border-radius: 5px;

    ion-segment-button {
        --background-checked: #14A83C !important;
        --color-checked: #fff !important;
        --color: #14A83C !important;
        --indicator-height: 0px !important;
        border-right: 1px solid;
        height: 35px;
        min-height: 35px;

        &:last-child {
            border-right: 0 !important;
        }
    }
}

.native-input:disabled {
    opacity: 1 !important;
}

.segment-changes {

    // border: 1px solid #14A83C;
    ion-segment-button {
        margin-bottom: 5px;
        margin-right: 5px;
        --background-checked: #14A83C !important;
        --color-checked: #fff !important;
        --color: #14A83C !important;
        --indicator-height: 0px !important;
        box-shadow: 0px 3px 6px #00000029;
        ;
        // &:last-child {
        //     border-right: 0 !important;
        // }
    }
}

.ion-border-right {
    border-right: 1px solid;
}

.ion-visibility-hidden {
    visibility: hidden;
}

.custome-button {
    --background: #E5EBEA;
    --color: #222 !important;
    --box-shadow: none;
    height: 30px;

    img {
        padding-right: 5px;
    }

    span {
        color: #222 !important;
    }
}

.text-color {
    color: #000 !important;
}

.download-icon {
    border: 1px solid;
    padding: 5px;
    background: #000;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 15px;
}

.my-custom-modal-css {
    --height: 300px !important;
    --width: 300px !important;
    --background: #fff;
}

// Table css
// .table-responsive{
//   overflow-x:auto;
//   width: 100%;
//   padding: 10px;
// }
.customeTable {
    border-collapse: collapse;
    width: 100%;
    min-width: 610px;
}

.customeTable td,
.customeTable th {
    border-bottom: 1px solid #dcdcdc;
    padding: 2px 8px;
    // border-bottom-width: 10px;
    background-color: #fff;
}

.customeTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;

    &:first-child {
        border-radius: 4px 0 0 0;
    }

    &:last-child {
        border-radius: 0 4px 0 0;
    }
}

.view-button {
    height: 30px !important;
}

// End Table css
.breadcrumb {
    padding: 0;
    margin: 0 !important;
    list-style: none;
    border-radius: 4px;
    background-color: transparent !important;
}

ol.breadcrumb li {
    display: inline-flex;
    font-size: 14px;

    ion-icon {
        padding-top: 3px
    }

    a:hover {
        text-decoration: none;
    }
}

.active-nav {
    color: black;

    &:hover {
        color: #000;
        text-decoration: none;
    }
}

.my-spinner {
    left: 50%;
    top: 50%;
    position: absolute;
    z-index: 9999;
    width: 120px;
    height: 120px;
}

.back-button {
    --box-shadow: none;
    --background: transparent;
    --color: #222;
    --padding-start: 5px;
    --padding-end: 5px;
    border: 1px solid #999;
    margin-top: 11px;
    height: 28px !important;
    border-radius: 5px;
    margin-right: 10px;

    ion-icon {
        color: #222;
    }
}

ion-button {
    text-transform: capitalize;
}

.view-detail {
    --background: transparent;
    --color: #4caf50;
    --box-shadow: none;
    font-size: 18px;
    --padding-start: 4px !important;
    --padding-end: 4px !important;

    .edit {
        color: #222;
    }

    .delete {
        color: rgba(180, 11, 11, 0.712)
    }
}

.custom-divider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: #d2d8d8;
}

.ion-margin-right {
    margin-right: 20px;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
    appearance: none;
}

@media (min-width: 576px) {
    .container {
        max-width: 760px !important;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 980px !important;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 1190px !important;
        padding: 0 30px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1700px !important;
        padding: 0 60px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #14a83c !important;
}

.page-link {
    color: #14a83c !important;
    padding: .4rem .75rem !important;
    margin: 5px !important;
    line-height: 21px !important;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.alert-radio-label {
    color: #222 !important;
}

.action-sheet-group::-webkit-scrollbar,
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 14px;
    display: block !important;
}

.action-sheet-group::-webkit-scrollbar-track,
.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px #5c645e;
    box-shadow: inset 0 0 2px #5c645e;
}

.action-sheet-group::-webkit-scrollbar-thumb,
.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: #22a13f;
    outline: 1px solid rgba(0, 123, 255, 0.25);
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-message.sc-ion-alert-md::-webkit-scrollbar {
    display: block;
}

// Mobile device Css start
@media screen and (max-width: 600px) {
    .margin-horizontal-0 {
        margin-left: 0;
        margin-right: 0;
    }

    .ion-padding-sm-0 {
        padding: 0 !important;
    }

    // .segment-wrap {
    //     flex-wrap: wrap;
    // }
    ion-segment-button {
        border: 1px solid;
        width: 150px;
    }

    .small-box {
        height: 100px !important;

        h6,
        h5 {
            font-size: 14px !important;
            margin: 4px;
        }
    }

    .big-size {
        font-size: 50px !important;
    }

    .big-size-image {
        height: 50px;
        width: 50px;
    }

    .toolbar-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .logo-size {
        text-align: center;
        display: block !important;
        width: 100px;
    }

    .my-tollbar {
        padding: 0 !important;

        h5 {
            font-size: 16px !important;
            margin-top: 10px;
        }
    }

    .title-header {
        margin-top: 0px !important;
    }

    .footer-content {
        font-size: 8px !important;
        text-align: center;
    }

    .container {
        padding: 0 5px !important;
    }

    ion-card {
        --margin-inline: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .my-footer {
        font-size: 9px;
        text-align: center;
    }

    .view-detail {
        font-size: 14px !important;
        // --padding-start: 4px !important;
        // --padding-end: 4px !important;
    }

    .customeTable td,
    .customeTable th {
        min-width: 111px !important;
        font-size: 13px;
    }

    ion-button {
        --padding-start: 4px !important;
        --padding-end: 4px !important;
    }

    .mt-40 {
        margin-top: 10px !important;
    }

    .page-item {
        ion-button {
            font-size: 12px !important;
            height: 26px !important;
            width: 84px !important;
        }

        .page-link {
            padding: 0.1rem 0.55rem !important;
        }
    }
}

// Mobile device Css End